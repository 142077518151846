@import "./theme";

h2 {
    text-align: center;
}

div.spacer {
    height: 1rem;
}

div.line {
    display: block;
    width: 100%;
    text-align: left;

    .key,
    .value {
        display: inline-block;
        vertical-align: top;
    }

    .key {
        padding-right: 0.4rem;
        font-weight: bolder;
    }
}

html,
body {
    height: 100%;
    overflow-y: hidden;
}
body {
    margin: 0;
}

.mat-card {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    /* border: 1px solid; */
    margin: 0 1rem 5px 1rem;
    padding: 0.5rem 0px;
    box-shadow: 1px 1px 0rem 2px rgba(5, 5, 5, 0.3) !important;
}
